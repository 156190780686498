<template>
  <div class="wrapper">
    <div v-if="filter_list">
      <div class="row col-md-3 mb-2">
        <input
          type="text"
          v-model.trim="search_text"
          @input="filter"
          class="form-control form-control-sm input-form shadow-none"
          placeholder="поиск по городу или названию СЦ"
        />
      </div>
      <table class="table table-sm table-hover">
        <tbody>
          <tr v-for="(item, idx) in filter_list" :key="item.id">
            <td>{{ idx + 1 }}</td>
            <td>
              <a :href="item.link" target="_blank">{{ item.gorod }}</a>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.adres }}</td>
            <td>{{ item.tel }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "../../../api";
export default {
  name: "ListScFromKalibrcompany",
  data: function() {
    return {
      list: null,
      filter_list: null,
      search_text: ""
    };
  },
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "/admin/list_sc_from_kalibrcompany/get_data.php"
      }).then(
        response => (this.list = this.filter_list = response.data.list_sc)
      );
    },
    filter() {
      this.filter_list = this.list.filter(
        item =>
          item.gorod.toLowerCase().includes(this.search_text.toLowerCase()) ||
          item.name.toLowerCase().includes(this.search_text.toLowerCase())
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 5px;
}
td {
  font-size: 0.7em;
}
</style>
